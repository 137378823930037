import appPageReducer from '@hkm/components/App/domain/reducers';
import images from '@hkm/components/App/imageQueue/domain/reducers';
import attendantAssignmentData from '@hkm/components/Attendant/shared/domain/reducers';
import housekeepingChangeBatchStatus from '@hkm/components/Housekeeping/Batch/domain/reducers';
import housekeepingDashboard from '@hkm/components/Housekeeping/Dashboard/domain/reducers';
import housekeepingDetails from '@hkm/components/Housekeeping/Details/domain/reducers';
import userContext from '@hkm/components/Login/domain/reducers';
import property from '@hkm/components/Menu/PropertySelector/domain/reducers';
import postingRoom from '@hkm/components/Posting/shared/domain/reducers';
import maintenanceAttachments from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/reducers';
import maintenanceCreate from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/reducers';
import maintenanceUpdateData from '@hkm/components/shared/Templates/Maintenance/shared/domain/reducers';
import taskManagementDashboard from '@hkm/components/TaskManagement/Dashboard/domain/reducers';
import taskManagementRelations from '@hkm/components/TaskManagement/relations/domain/reducers';
import taskManagementTaskDetails from '@hkm/components/TaskManagement/TaskPreview/domain/reducers';
import { combineReducers } from 'redux';

const createRootReducer = () =>
  combineReducers({
    housekeepingDashboard,
    housekeepingDetails,
    housekeepingChangeBatchStatus,
    maintenanceUpdateData,
    maintenanceAttachments,
    maintenanceCreate,
    attendantAssignmentData,
    postingRoom,
    property,
    userContext,
    images,
    app: appPageReducer,
    tasks: combineReducers({
      dashboard: taskManagementDashboard,
      taskDetails: taskManagementTaskDetails,
      relations: taskManagementRelations,
    }),
  });

export default createRootReducer;
