/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InactivityModalSelectors } from '@hkm/features/app/modals/inactivity/Modal.selectors';
import { useInactivityModalPresenterContext } from '@hkm/features/app/modals/inactivity/presenter/context';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
  AcModalPattern,
} from '@ac/mobile-components/dist/components/modal';
import { AlignItems } from '@ac/mobile-components/dist/enums';
import { FlexDirection, JustifyContent } from '@ac/web-components';

export const InactivityModal = (): JSX.Element | null => {
  const { t } = useTranslation();
  const presenter = useInactivityModalPresenterContext();

  const terminate = useCallback(() => presenter.cancel(), [presenter]);
  const extend = useCallback(() => presenter.confirm(), [presenter]);

  return (
    <AcModal
      isOpen={presenter.state.isVisible}
      onClose={terminate}
      pattern={AcModalPattern.Normal}
      testSelector={InactivityModalSelectors.modal}
    >
      <AcModalHeader showCloseButton={true}>
        {t('INACTIVITY_MODAL.TITLE')}
      </AcModalHeader>

      <AcModalBody>
        <ac-flex
          class="ac-gap-md ac-padding-sm"
          direction={FlexDirection.column}
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.center}
        >
          <ac-text data-test-selector={InactivityModalSelectors.description}>
            {t('INACTIVITY_MODAL.DESCRIPTION')}
          </ac-text>
          <ac-timer
            data-test-selector={InactivityModalSelectors.timer}
            onFinishCountingCallback={terminate}
            time={presenter.state.inputData?.duration ?? 60}
          />
        </ac-flex>
      </AcModalBody>

      <AcModalFooter>
        <ac-flex class="ac-gap-md width-percentage-100">
          <ac-box>
            <AcButton
              onClick={terminate}
              pattern={AcButtonPattern.Light}
              className="width-percentage-100"
              testSelector={InactivityModalSelectors.actions.terminate}
            >
              <AcButtonContent text={t('INACTIVITY_MODAL.ACTIONS.LOG_OUT')} />
            </AcButton>
          </ac-box>

          <ac-box>
            <AcButton
              onClick={extend}
              className="width-percentage-100"
              testSelector={InactivityModalSelectors.actions.extend}
            >
              <AcButtonContent text={t('INACTIVITY_MODAL.ACTIONS.EXTEND')} />
            </AcButton>
          </ac-box>
        </ac-flex>
      </AcModalFooter>
    </AcModal>
  );
};
