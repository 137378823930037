import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import classNames from 'classnames';

import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { BadgeSize } from '@ac/web-components';

interface DayUseBadgeProps extends Styleable, Testable {}

const DayUseBadge: FC<DayUseBadgeProps> = (props: DayUseBadgeProps) => {
  const { t } = useTranslation();
  const className = classNames('bordered-badge', props.className);

  return (
    <ac-badge
      class={className}
      content={t('GLOBAL.DAY_USE')}
      data-test-selector={props.testSelector}
      color={BadgeColor.White}
      size={BadgeSize.lg}
    />
  );
};

export default memo(DayUseBadge);
