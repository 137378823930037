import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useMobileFeaturesContext } from '@hkm/features/app/store/features/context';

import { guid } from '@ac/library-utils/dist/utils';
import { IconName } from '@ac/web-components';

interface Page {
  guid: string;
  text: string;
  link: string;
  testSelector?: string;
}

interface Segment {
  guid: string;
  icon: IconName;
  title: string;
  pages: Page[];
}

export interface MenuStore {
  segments: Segment[];
}

export function useMenuStore(): MenuStore {
  const { t } = useTranslation();
  const features = useMobileFeaturesContext();

  const housekeepingPages = useMemo(() => {
    const pages: Page[] = [];

    if (features.housekeeping.view.isEnabled) {
      pages.push({
        guid: guid('page-housekeeping'),
        text: t('MENU.LINKS.HOUSEKEEPING_DASHBOARD'),
        link: APP_ROUTES.HOUSEKEEPING.DASHBOARD,
        testSelector: 'navigation-link-housekeeping-dashboard',
      });

      pages.push({
        guid: guid('page-housekeeping-v2'),
        text: t('MENU.LINKS.HOUSEKEEPING_DASHBOARD_V2'),
        link: APP_ROUTES.HOUSEKEEPING.DASHBOARD_V2,
        testSelector: 'navigation-link-housekeeping-dashboard',
      });
    }

    if (features.maintenance.view.isEnabled) {
      pages.push({
        guid: guid('page-maintenance'),
        text: t('MENU.LINKS.MAINTENANCE_DASHBOARD'),
        link: APP_ROUTES.MAINTENANCE.DASHBOARD,
        testSelector: 'navigation-link-maintenance-dashboard',
      });
    }

    if (features.attendantAssignment.summary.isEnabled) {
      pages.push({
        guid: guid('page-attendant-assignment'),
        text: t('MENU.LINKS.ATTENDANT_ASSIGNMENTS'),
        link: APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY,
        testSelector: 'navigation-link-attendant-assignments',
      });
    }

    if (features.attendantAssignment.summaryV2.isEnabled) {
      pages.push({
        guid: guid('page-attendant-assignment-v2'),
        text: t('MENU.LINKS.ATTENDANT_ASSIGNMENTS_V2'),
        link: APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY_V2,
        testSelector: 'navigation-link-attendant-assignments-v2',
      });
    }

    if (features.posting.view.isEnabled) {
      pages.push({
        guid: guid('page-posting'),
        text: t('MENU.LINKS.POSTING'),
        link: APP_ROUTES.MOBILE_POSTING.DASHBOARD,
        testSelector: 'navigation-link-posting-dashboard',
      });
    }

    return pages;
  }, [t, features]);

  const tasksPages = useMemo(() => {
    const pages: Page[] = [];

    if (features.task.view.isEnabled) {
      pages.push({
        guid: guid('page-task'),
        text: t('MENU.LINKS.TASK_MANAGEMENT_DASHBOARD'),
        link: APP_ROUTES.TASK_MANAGEMENT.DASHBOARD,
        testSelector: 'navigation-link-task-management-dashboard',
      });
    }

    return pages;
  }, [t, features]);

  const segments = useMemo(() => {
    const items: Segment[] = [];

    if (housekeepingPages.length) {
      items.push({
        guid: guid('segment-housekeeping'),
        icon: IconName.housekeeping,
        title: t('MENU.HOUSEKEEPING'),
        pages: housekeepingPages,
      });
    }

    if (tasksPages.length) {
      items.push({
        guid: guid('segment-task'),
        icon: IconName.task,
        title: t('MENU.TASK_MANAGEMENT'),
        pages: tasksPages,
      });
    }

    return items;
  }, [housekeepingPages, t, tasksPages]);

  return {
    segments,
  };
}
