import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CountWithDescription from '@hkm/components/Attendant/SheetSummary/body/CountWithDesciption/CountWithDescription';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { MembershipInfo } from '@hkm/components/Attendant/SheetSummary/body/VipsAndMemberships/MembershipInfo';
import { SheetSummaryMembership } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryMembership';
import { SheetSummaryValue } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryValue';
import { BadgeWithCount } from '@hkm/components/shared/BadgeWithCount/BadgeWithCount';
import classNames from 'classnames';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { DividerSpacing, IconName } from '@ac/web-components';

export interface VipsAndMembershipsProps {
  vips: SheetSummaryValue[];
  memberships: SheetSummaryMembership[];
}

export const VipsAndMemberships: React.FC<VipsAndMembershipsProps> = (
  props: VipsAndMembershipsProps
) => {
  const { t } = useTranslation();

  const membershipsTotal = props.memberships.reduce((sum, membership) => {
    const totalMembershipCount = membership.levels.reduce(
      (levelsSum, level) => levelsSum + level.count,
      0
    );

    return sum + totalMembershipCount;
  }, 0);

  const vipsTotal = useMemo(() => {
    return props.vips.reduce((sum, vip) => sum + vip.count, 0);
  }, [props.vips]);

  const total = vipsTotal + membershipsTotal;

  const renderSubtitle = useMemo(
    () => (
      <ac-flex alignItems={AlignItems.center}>
        <AcText
          weight={TextWeight.Semibold}
          size={TextSize.H1}
          equalLineHeight={true}
          className="ac-spacing-right-xs"
          testSelector="vips-and-memberships-total"
        >
          {total}
        </AcText>
        <ac-flex direction={FlexDirection.column}>
          <AcText
            size={TextSize.Main2}
            color={MobileColor.Gray3}
            testSelector="vips-and-memberships-total-vips"
          >
            {vipsTotal}{' '}
            {t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.VIPS')}
          </AcText>
          <AcText
            size={TextSize.Main2}
            color={MobileColor.Gray3}
            testSelector="vips-and-memberships-total-memberships"
          >
            {membershipsTotal}{' '}
            {t(
              'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.MEMBERSHIPS'
            )}
          </AcText>
        </ac-flex>
      </ac-flex>
    ),
    [t, vipsTotal, membershipsTotal, total]
  );

  const renderContent = useMemo(
    () => (
      <ac-flex direction={FlexDirection.column}>
        <CountWithDescription
          count={vipsTotal}
          description={t(
            'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.VIPS'
          )}
        />
        <ac-flex wrapped={true}>
          {props.vips.map((vip, index) => (
            <BadgeWithCount
              key={index}
              count={vip.count}
              content={vip.description}
              color={vip.color}
              className={classNames(
                index !== props.vips.length - 1 && 'ac-spacing-right-sm',
                'ac-spacing-bottom-sm'
              )}
              testSelector="vips-and-memberships-vip-badge"
            />
          ))}
        </ac-flex>

        <ac-divider class="ac-spacing-top-md ac-spacing-bottom-md" />

        <CountWithDescription
          className="ac-spacing-bottom-md"
          count={membershipsTotal}
          description={t(
            'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.MEMBERSHIPS'
          )}
        />

        {props.memberships.map((membership, index) => {
          const isLast = index === props.memberships.length - 1;

          return (
            <React.Fragment key={index}>
              <MembershipInfo membership={membership} />
              {!isLast && <ac-divider spacing={DividerSpacing.md} />}
            </React.Fragment>
          );
        })}
      </ac-flex>
    ),
    [t, vipsTotal, membershipsTotal, props.memberships, props.vips]
  );

  return (
    <SheetsSummaryAccordion
      icon={IconName.vip}
      title={t(
        'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.TITLE'
      )}
      renderSubtitle={renderSubtitle}
      expandDisabled={total === 0}
      renderContent={renderContent}
    />
  );
};

export default memo(VipsAndMemberships);
