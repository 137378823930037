import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import { getCleanTypesMap } from '@hkm/components/App/domain/selectors';
import AttendantRoomItemHeader from '@hkm/components/Attendant/RoomList/Body/AttendantRoomHeader/AttendantRoomItemHeader';
import { AttendantSheetState } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentData';
import { selectAssignedSheetState } from '@hkm/components/Attendant/shared/domain/selectors';
import {
  selectActiveProperty,
  selectEffectiveValues,
  selectPropertyDateFormats,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';
import { GuestServiceBadge } from '@hkm/components/shared/GuestService/GuestServiceBadge';
import MaintenanceStatusBadge from '@hkm/components/shared/MaintenanceStatusBadge/MaintenanceStatusBadge';
import { ReservationMovementDashboardItem } from '@hkm/components/shared/ReservationMovements/ReservationMovementDashboardItem';
import { RoomConditionsBadge } from '@hkm/components/shared/RoomConditions/RoomConditionsBadge';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import { getCurrentMainReservation } from '@hkm/shared/reservedKind/getCurrentMainReservation';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { dayjs } from '@hkm/utils/dayjs-extended';

import {
  AttendantTaskProgressType,
  FrontDeskStatus,
  ReservationStatus,
  RoomMaintenanceStatus,
  RoomStatus,
} from '@ac/library-api';
import {
  AcFormElement,
  AcFormGroup,
  AcFormRow,
} from '@ac/mobile-components/dist/components/form-element';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  FlexDirection,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { BadgeSize, BadgeTheme, IconName, Size } from '@ac/web-components';

import './AttendantRoomItem.css';

interface AttendantRoomItemProps {
  room: AttendantRoom;
  taskType: AttendantTaskProgressType;
}

const AttendantRoomItem: FC<AttendantRoomItemProps> = (
  props: AttendantRoomItemProps
) => {
  const { t } = useTranslation();
  const prefix = 'attendant-room-item';
  const isGreenServiceEnabled = useSelector(
    selectEffectiveValues
  )?.greenService;
  const propertyCheckInTime =
    useSelector(selectActiveProperty)?.checkInTime ?? '';
  const propertyCheckOutTime =
    useSelector(selectActiveProperty)?.checkOutTime ?? '';
  const cleanTypesMap = useSelector(getCleanTypesMap);
  const cleanType = cleanTypesMap.get(props.room.serviceType?.code || '');

  const { shortDate: shortDateFormat } = useSelector(selectPropertyDateFormats);
  const mainReservation = useMemo(() => {
    return getCurrentMainReservation(
      props.room.reservations.map((reservation) => ({
        ...reservation,
        status: reservation.status?.code as ReservationStatus,
        eta: reservation.eta,
        isDayUse: reservation.isDayUse,
      })),
      propertyCheckInTime,
      propertyCheckOutTime
    );
  }, [props.room.reservations, propertyCheckInTime, propertyCheckOutTime]);

  const isCurrentReservationDueIn = useMemo(() => {
    return props.room.reservations[0]?.status?.code === ReservationStatus.DI;
  }, [props.room.reservations]);

  const showGreenService = isGreenServiceEnabled && props.room.greenService;
  const showLinenChange = props.room.linenChange;
  const showEtaEtdGuaranteed = !!(
    mainReservation?.status === ReservationStatus.IH &&
    mainReservation?.isTimeGuaranteed
  );

  const arrivalDate = props.room.currentMainReservation?.arrivalDate
    ? dayjs(props.room.currentMainReservation?.arrivalDate).format(
        shortDateFormat
      )
    : undefined;
  const departureDate = props.room.currentMainReservation?.arrivalDate
    ? dayjs(props.room.currentMainReservation?.departureDate).format(
        shortDateFormat
      )
    : undefined;

  const hasRoomConditions = !!props.room.roomConditions?.length;
  const navigate = useNavigate();
  const assignedSheetState: AttendantSheetState = useSelector(
    selectAssignedSheetState
  );

  const redirectToDetails = useCallback(() => {
    navigate(
      generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
        sheetId: assignedSheetState.data?.id,
        roomId: props.room.id,
      })
    );
  }, [props.room.id, assignedSheetState.data?.id, navigate]);

  const { isInPendingRoomQueue, serviceRequested } = props.room;

  return (
    <>
      <AcTile className={prefix}>
        <div onClick={redirectToDetails}>
          <AttendantRoomItemHeader room={props.room} taskType={props.taskType}>
            {props.room.roomNumber}
          </AttendantRoomItemHeader>

          <div
            className={`${prefix}-content ac-spacing-top-md ${
              isInPendingRoomQueue || serviceRequested ? 'is-alert-bar' : ''
            }`}
          >
            <AcFormGroup className="ac-spacing-top-md">
              {props.room.frontdeskStatus?.code ===
                FrontDeskStatus.Occupied && (
                <AcFormRow>
                  <AcFormElement
                    label={t('ROOM_DETAILS.ARRIVAL_DATE')}
                    testSelector={formatTestSelector(prefix, 'arrivalDate')}
                  >
                    {arrivalDate}
                  </AcFormElement>
                  <AcFormElement
                    label={t('ROOM_DETAILS.DEPARTURE_DATE')}
                    testSelector={formatTestSelector(prefix, 'departureDate')}
                  >
                    {departureDate}
                  </AcFormElement>
                </AcFormRow>
              )}

              <AcFormRow>
                <AcFormElement
                  label={t('GLOBAL.ROOM_TYPE.LONG')}
                  testSelector={formatTestSelector(prefix, 'roomType')}
                >
                  {props.room.roomType?.code}
                </AcFormElement>
                <AcFormElement
                  label={t('GLOBAL.ROOM_STATUS.LONG')}
                  testSelector={formatTestSelector(prefix, 'roomStatus')}
                >
                  <RoomStatusBadge
                    status={props.room.roomStatus?.code as RoomStatus}
                    showLabel={true}
                  />
                </AcFormElement>
              </AcFormRow>

              <AcFormRow>
                <AcFormElement
                  label={t('GLOBAL.CLEARING_TYPE.TITLE')}
                  testSelector={formatTestSelector(prefix, 'cleaningType')}
                >
                  {props.room.serviceType ? (
                    <ac-badge
                      size={BadgeSize.lg}
                      content={props.room.serviceType.code}
                      color={cleanType?.color ?? BadgeColor.Default}
                      theme={BadgeTheme.informationalTheme}
                    />
                  ) : (
                    t('GLOBAL.NONE')
                  )}
                </AcFormElement>
                <AcFormElement
                  label={t('GLOBAL.FRONT_DESK_STATUS.LONG')}
                  testSelector={formatTestSelector(prefix, 'frontDesk')}
                >
                  {props.room.frontdeskStatus?.description}
                </AcFormElement>
              </AcFormRow>

              {!isCurrentReservationDueIn ? (
                <AcFormRow>
                  <ac-flex direction={FlexDirection.column}>
                    <ReservationMovementDashboardItem
                      room={props.room}
                      className="ac-spacing-bottom-md"
                      testSelector={formatTestSelector(
                        prefix,
                        'reservation-movement'
                      )}
                    />

                    {showGreenService && (
                      <ac-flex
                        class="ac-spacing-bottom-md"
                        alignItems={AlignItems.center}
                      >
                        <ac-icon
                          icon={IconName.greenService}
                          size={Size.md}
                          class="ac-spacing-right-md"
                        />
                        <AcText
                          color={MobileColor.Black}
                          size={TextSize.Main2}
                          testSelector={`${prefix}-greenService`}
                        >
                          {t('GLOBAL.GREEN_SERVICE.TITLE')}
                        </AcText>
                      </ac-flex>
                    )}

                    {showLinenChange && (
                      <ac-flex
                        class="ac-spacing-bottom-md"
                        alignItems={AlignItems.center}
                      >
                        <ac-icon
                          icon={IconName.linenChange}
                          size={Size.md}
                          class="ac-spacing-right-md"
                        />
                        <AcText
                          color={MobileColor.Black}
                          size={TextSize.Main2}
                          testSelector={`${prefix}-linenChange`}
                        >
                          {t('GLOBAL.LINEN_CHANGE')}
                        </AcText>
                      </ac-flex>
                    )}

                    <GuestServiceBadge
                      room={props.room}
                      flexDirection={FlexDirection.column}
                      className="ac-flex-align-items-center ac-spacing-bottom-md"
                    />

                    {hasRoomConditions && (
                      <RoomConditionsBadge
                        roomConditions={props.room.roomConditions}
                      />
                    )}

                    {showEtaEtdGuaranteed && (
                      <ac-flex>
                        <EtaEtdGuaranteedBadge />
                      </ac-flex>
                    )}
                  </ac-flex>

                  {props.room.currentMaintenance && (
                    <AcFormRow>
                      <AcFormElement
                        label={t('GLOBAL.MAINTENANCE_STATUS.TITLE')}
                        testSelector={formatTestSelector(
                          prefix,
                          'cleaningType'
                        )}
                      >
                        <MaintenanceStatusBadge
                          status={
                            props.room.currentMaintenance?.status
                              ?.code as RoomMaintenanceStatus
                          }
                          testSelector={formatTestSelector(
                            prefix,
                            'maintenance'
                          )}
                        />
                      </AcFormElement>
                    </AcFormRow>
                  )}
                </AcFormRow>
              ) : (
                <>
                  {props.room.currentMaintenance && (
                    <AcFormRow>
                      <AcFormElement
                        label={t('GLOBAL.MAINTENANCE_STATUS.TITLE')}
                        testSelector={formatTestSelector(
                          prefix,
                          'cleaningType'
                        )}
                      >
                        <MaintenanceStatusBadge
                          status={
                            props.room.currentMaintenance?.status
                              ?.code as RoomMaintenanceStatus
                          }
                          testSelector={formatTestSelector(
                            prefix,
                            'maintenance'
                          )}
                        />
                      </AcFormElement>
                    </AcFormRow>
                  )}

                  <ReservationMovementDashboardItem
                    room={props.room}
                    className="ac-spacing-bottom-md"
                    testSelector={formatTestSelector(
                      prefix,
                      'reservation-movement'
                    )}
                  />

                  {(showGreenService || showLinenChange) && (
                    <AcFormRow>
                      {showGreenService && (
                        <ac-flex alignItems={AlignItems.center}>
                          <ac-icon
                            icon={IconName.greenService}
                            size={Size.md}
                            class="ac-spacing-right-md"
                          />
                          <AcText
                            color={MobileColor.Black}
                            size={TextSize.Main2}
                            testSelector={`${prefix}-greenService`}
                          >
                            {t('GLOBAL.GREEN_SERVICE.TITLE')}
                          </AcText>
                        </ac-flex>
                      )}

                      {showLinenChange && (
                        <ac-flex alignItems={AlignItems.center}>
                          <ac-icon
                            icon={IconName.linenChange}
                            size={Size.md}
                            class="ac-spacing-right-md"
                          />
                          <AcText
                            color={MobileColor.Black}
                            size={TextSize.Main2}
                            testSelector={`${prefix}-linenChange`}
                          >
                            {t('GLOBAL.LINEN_CHANGE')}
                          </AcText>
                        </ac-flex>
                      )}
                    </AcFormRow>
                  )}

                  <GuestServiceBadge
                    room={props.room}
                    flexDirection={FlexDirection.column}
                    className="ac-flex-align-items-center"
                  />

                  {hasRoomConditions && (
                    <RoomConditionsBadge
                      roomConditions={props.room.roomConditions}
                    />
                  )}

                  {showEtaEtdGuaranteed && (
                    <ac-flex>
                      <EtaEtdGuaranteedBadge />
                    </ac-flex>
                  )}
                </>
              )}
            </AcFormGroup>
          </div>
        </div>
      </AcTile>
    </>
  );
};

export default memo(AttendantRoomItem);
