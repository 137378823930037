import { useCallback, useState } from 'react';
import {
  ActivityState,
  getDefaultActivityState,
} from '@hkm/features/app/store/activity/state';

export interface ActivityStore {
  state: ActivityState;
  browserTabVisibilityChanged(): void;
}

export function useActivityStore(): ActivityStore {
  const [state, setState] = useState(getDefaultActivityState());

  const browserTabVisibilityChanged = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isBrowserTabVisible: !prevState.isBrowserTabVisible,
    }));
  }, []);

  return {
    state,
    browserTabVisibilityChanged,
  };
}
