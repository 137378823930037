import { createContext } from 'react';

import { HousekeepingStatus } from '@ac/library-api';
import { ModalPresenterStore } from '@ac/react-infrastructure';
import { useDefinedContext } from '@ac/react-infrastructure/dist/features';

export interface FinalizeTaskProcessModalOutput {
  housekeepingStatus: HousekeepingStatus;
}

export type FinalizeTaskProcessModalPresenterStore = ModalPresenterStore<
  void,
  FinalizeTaskProcessModalOutput
>;

export const FinalizeTaskProcessModalPresenterContext = createContext<
  FinalizeTaskProcessModalPresenterStore | undefined
>(undefined);

export const useFinalizeTaskProcessModalPresenterContext =
  (): FinalizeTaskProcessModalPresenterStore =>
    useDefinedContext(FinalizeTaskProcessModalPresenterContext);
