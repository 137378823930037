import AppConstants from '@hkm/constants/app.constants';

import { PropertyContext } from '@ac/library-api';

export interface AppFeatureActions {
  defaultLanguage: string;
  availableLanguages: string[];
  sessionIdleTimeoutInSeconds: number | undefined;
  sessionIdleTimeoutWarningInSeconds: number | undefined;
}

export const getAppFeature = (
  businessContext: PropertyContext | undefined
): AppFeatureActions => {
  const settings = businessContext?.settings.processed;

  return {
    defaultLanguage:
      settings?.housekeeping.supportedHousekeepingMobileLanguages?.value
        .languageCode ?? AppConstants.FALLBACK_LANGUAGE,
    availableLanguages: settings?.housekeeping
      .supportedHousekeepingMobileLanguages?.value.supportedLanguages ?? [
      AppConstants.FALLBACK_LANGUAGE,
    ],
    sessionIdleTimeoutInSeconds:
      settings?.housekeeping.mobileSessionIdleTimeoutInSeconds,
    sessionIdleTimeoutWarningInSeconds:
      settings?.housekeeping.mobileSessionIdleTimeoutWarningInSeconds,
  };
};
