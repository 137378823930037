import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import * as housekeepingDashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import { HousekeepingSort } from '@hkm/components/Housekeeping/shared/enum/housekeepingSort';
import * as taskManagementDashboardActions from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import { TaskStatusDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import classNames from 'classnames';
import { Dispatch } from 'redux';

import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';

const LINK_TO_RESET_STATE_CALLBACK = {
  [APP_ROUTES.HOUSEKEEPING.DASHBOARD]: (dispatch: Dispatch) => {
    dispatch(housekeepingDashboardActions.pagination.setFilters({}));
    dispatch(
      housekeepingDashboardActions.setHousekeepingMode(DashboardMode.Default)
    );
    dispatch(
      housekeepingDashboardActions.pagination.setSort(HousekeepingSort.Sequence)
    );
  },
  [APP_ROUTES.TASK_MANAGEMENT.DASHBOARD]: (
    dispatch: Dispatch,
    taskStatusDictionary: TaskStatusDictionary[]
  ) => {
    dispatch(
      taskManagementDashboardActions.clearFilteringState(taskStatusDictionary)
    );
  },
};

interface MenuLinkItemProps extends Testable {
  text: string;
  link: string;
}

export const MenuLink: FC<MenuLinkItemProps> = (props: MenuLinkItemProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const tasksRelations = useSelector(getRelationsState);
  const presenter = useMenuPanelPresenterContext();
  const isActive = location.pathname === props.link;

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      presenter.hide();
      const requireFiltersReset = Object.keys(
        LINK_TO_RESET_STATE_CALLBACK
      ).some((link) => link === props.link);

      if (isActive && requireFiltersReset) {
        const resetCallback = LINK_TO_RESET_STATE_CALLBACK[props.link];
        resetCallback(dispatch, tasksRelations.taskStatuses);
        e.preventDefault();
      }
    },
    [presenter, isActive, props.link, dispatch, tasksRelations.taskStatuses]
  );

  return (
    <div
      className={classNames('link-item', {
        active: isActive,
      })}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <Link to={props.link} onClick={onClick} className="link">
        {props.text}
      </Link>
    </div>
  );
};
