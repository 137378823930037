import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';

import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
} from '@ac/mobile-components/dist/components/modal';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { BadgeSize } from '@ac/web-components';

import './VipOrMemberTemplate.css';

interface VipOrMemberTemplateProps extends Testable {
  disableModal?: boolean;
  vipOrMemberBadges: VipOrMemberBadge[];
}

const VipOrMemberTemplate: FC<VipOrMemberTemplateProps> = (
  props: VipOrMemberTemplateProps
) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  function openModal(): void {
    if (!props.disableModal) {
      setModalVisibility(true);
    }
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  return (
    <>
      <ac-flex
        wrapped={true}
        class="ac-gap-sm"
        data-test-selector={props.testSelector}
      >
        {props.vipOrMemberBadges.map((data, index) => (
          <ac-badge
            key={index}
            content={data.text}
            color={data.color}
            size={BadgeSize.lg}
            onClick={openModal}
          />
        ))}
      </ac-flex>

      <AcModal
        className="vip-or-member-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader
          className="vip-or-member-modal-header"
          showCloseButton={true}
        >
          {t('ROOM_DETAILS.VIP_OR_MEMBER')}
        </AcModalHeader>

        <AcModalBody className="vip-or-member-modal-body">
          {props.vipOrMemberBadges.map((data, index) => (
            <div key={index} className="ac-spacing-top-sm">
              <ac-badge
                content={data.text}
                color={data.color}
                text={data.description}
                size={BadgeSize.lg}
                data-test-selector={`${props.testSelector}-${data.text}`}
              />
            </div>
          ))}
        </AcModalBody>

        <AcModalFooter>
          <AcButton onClick={closeModal} style={{ width: '100%' }}>
            <AcButtonContent text={t('GLOBAL.CLOSE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(VipOrMemberTemplate);
