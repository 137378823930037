import { useEffect, useMemo } from 'react';
import { useIdleTimeoutCookieController } from '@hkm/features/app/store/activity/commands/useIdleTimeoutCookieController';

import { debounce } from '@ac/library-utils/dist/utils';

const EVENTS = [
  'keydown',
  'click',
  'pointerdown',
  'pointermove',
  'touchstart',
  'touchmove',
  'touchend',
  'wheel',
  'focus',
  'blur',
];

export function useActivityCookieEventUpdater() {
  const cookie = useIdleTimeoutCookieController();

  const setCookie = useMemo(
    () =>
      debounce(() => {
        cookie.update();
      }, 200),
    [cookie]
  );

  useEffect(() => {
    setCookie();

    EVENTS.forEach((event) => {
      document.addEventListener(event, setCookie);
    });

    return () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, setCookie);
      });
    };
  }, [setCookie]);
}
