import { useMemo } from 'react';
import { getAppFeature } from '@hkm/domain/featureResolver/features/app/app';
import { getAttendantAssignmentFeature } from '@hkm/domain/featureResolver/features/attendantAssignment/attendantAssignment';
import { getHousekeepingFeature } from '@hkm/domain/featureResolver/features/housekeeping/housekeeping';
import { getMaintenanceFeature } from '@hkm/domain/featureResolver/features/maintenance/maintenance';
import { getPostingFeature } from '@hkm/domain/featureResolver/features/posting/posting';
import { getTasksFeature } from '@hkm/domain/featureResolver/features/task/task';

import { FeatureReport } from '@ac/library-api';
import { usePropertyBusinessContext } from '@ac/react-infrastructure';

export interface MobileFeaturesStore {
  app: {
    defaultLanguage: string;
    availableLanguages: string[];
    sessionIdleTimeoutInSeconds: number | undefined;
    sessionIdleTimeoutWarningInSeconds: number | undefined;
  };
  attendantAssignment: {
    summary: FeatureReport;
    summaryV2: FeatureReport;
  };
  maintenance: {
    view: FeatureReport;
  };
  housekeeping: {
    view: FeatureReport;
  };
  task: {
    view: FeatureReport;
  };
  posting: {
    view: FeatureReport;
  };
}

export function useMobileFeaturesStore(): MobileFeaturesStore {
  const propertyContext = usePropertyBusinessContext();

  const app = useMemo(() => {
    const features = getAppFeature(propertyContext.state.businessContext);

    return {
      defaultLanguage: features.defaultLanguage,
      availableLanguages: features.availableLanguages,
      sessionIdleTimeoutInSeconds: features.sessionIdleTimeoutInSeconds,
      sessionIdleTimeoutWarningInSeconds:
        features.sessionIdleTimeoutWarningInSeconds,
    };
  }, [propertyContext.state.businessContext]);

  const attendantAssignment = useMemo(() => {
    const features = getAttendantAssignmentFeature(
      propertyContext.state.businessContext
    );

    return {
      summary: features.summary.validate(),
      summaryV2: features.summaryV2.validate(),
    };
  }, [propertyContext.state.businessContext]);

  const maintenance = useMemo(() => {
    const features = getMaintenanceFeature(
      propertyContext.state.businessContext
    );

    return {
      view: features.view.validate(),
    };
  }, [propertyContext.state.businessContext]);

  const housekeeping = useMemo(() => {
    const features = getHousekeepingFeature(
      propertyContext.state.businessContext
    );

    return {
      view: features.view.validate(),
    };
  }, [propertyContext.state.businessContext]);

  const task = useMemo(() => {
    const features = getTasksFeature(propertyContext.state.businessContext);

    return {
      view: features.view.validate(),
    };
  }, [propertyContext.state.businessContext]);

  const posting = useMemo(() => {
    const features = getPostingFeature(propertyContext.state.businessContext);

    return {
      view: features.view.validate(),
    };
  }, [propertyContext.state.businessContext]);

  return {
    app,
    attendantAssignment,
    maintenance,
    housekeeping,
    task,
    posting,
  };
}
