import { Logger, LoggerService } from '@ac/library-api';
import {
  LogDetails,
  LogParams,
} from '@ac/library-utils/dist/declarations/logger/logger';

interface Options {
  forceSend?: boolean;
  logParams?: LogParams;
}

enum LogLevel {
  Verbose = 'verbose',
  Debug = 'debug',
  Information = 'information',
  Warning = 'warning',
  Error = 'error',
  Fatal = 'fatal',
}

let instance: LoggerService | undefined;

type Log = LogDetails | string;

const getInstance = async (): Promise<LoggerService> => {
  if (!instance) {
    Logger.setAsMain();
    instance = await Logger.init('housekeepingMobile');
  }

  return instance;
};

async function report(
  level: LogLevel,
  log: Log,
  options?: Options
): Promise<void> {
  const kibana = await getInstance();
  const action = {
    [LogLevel.Verbose]: () => kibana.verbose(log, options?.logParams),
    [LogLevel.Debug]: () => kibana.debug(log, options?.logParams),
    [LogLevel.Information]: () => kibana.information(log, options?.logParams),
    [LogLevel.Warning]: () => kibana.warning(log, options?.logParams),
    [LogLevel.Error]: () => kibana.error(log, options?.logParams),
    [LogLevel.Fatal]: () => kibana.fatal(log, options?.logParams),
  };

  action[level]?.();

  if (options?.forceSend) {
    await kibana.forceSend();
  }
}

export class Kibana {
  static async info(log: Log, options?: Options): Promise<Kibana> {
    await report(LogLevel.Information, log, options);

    return Kibana;
  }

  static async warning(log: Log, options?: Options): Promise<Kibana> {
    await report(LogLevel.Warning, log, options);

    return Kibana;
  }

  static async error(log: Log, options?: Options): Promise<Kibana> {
    await report(LogLevel.Error, log, options);

    return Kibana;
  }

  static async fatal(log: Log, options?: Options): Promise<Kibana> {
    await report(LogLevel.Fatal, log, options);

    return Kibana;
  }

  static async verbose(log: Log, options?: Options): Promise<Kibana> {
    await report(LogLevel.Verbose, log, options);

    return Kibana;
  }

  static async debug(log: Log, options?: Options): Promise<Kibana> {
    await report(LogLevel.Debug, log, options);

    return Kibana;
  }
}
