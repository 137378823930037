import { NotesStore } from '@hkm/components/App/domain/interfaces/BookingNotesStore';
import { DictionariesStore } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import { OverlayMessagesState } from '@hkm/components/App/domain/interfaces/OverlayMessages';
import { Store } from '@hkm/store/interfaces/store';
import { arrayToMapByKey } from '@hkm/utils/arrayToMapByKey';
import { createSelector } from 'reselect';

import {
  CustomerDetails,
  FeatureToggleItem,
  RoomQueueDetailsViewsDto,
} from '@ac/library-api';

export const getDictionaries = (state: Store): DictionariesStore =>
  state.app.dictionaries;
export const getFeatureToggles = (state: Store): FeatureToggleItem[] =>
  state.app.featureToggles;
export const getRoomTypes = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.roomType
);
export const getRoomStates = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.roomStatus
);
export const getHousekeepingStates = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.housekeepingStatus
);
export const getFrontDeskStates = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.frontDeskStatus
);
export const getRoomCategories = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.roomCategory
);
export const getRoomClasses = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.roomClass
);
export const getRooms = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.room
);
export const getAttributes = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.attribute
);
export const getFloors = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.floor
);
export const getCleanTypes = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.cleanType
);
export const getHousekeepingAttendants = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.housekeepingAttendant
);
export const getSections = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.housekeepingSection
);
export const getSupportedUiLanguages = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.supportedUiLanguages
);
export const getOutOfOrderReasons = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.outOfOrderReason
);
export const getRoomLocations = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.roomLocation
);
export const getNoteTypes = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.noteTypes
);
export const getRoomConditions = createSelector(
  getDictionaries,
  (dictionaries) => dictionaries.roomCondition
);
export const getRoomConditionsMap = createSelector(
  getRoomConditions,
  (states) => arrayToMapByKey(states, 'code')
);
export const getRoomTypesMap = createSelector(getRoomTypes, (types) =>
  arrayToMapByKey(types, 'code')
);
export const getRoomStatesMap = createSelector(getRoomStates, (states) =>
  arrayToMapByKey(states, 'code')
);
export const getHousekeepingStatesMap = createSelector(
  getHousekeepingStates,
  (states) => arrayToMapByKey(states, 'code')
);
export const getFrontDeskStatesMap = createSelector(
  getFrontDeskStates,
  (states) => arrayToMapByKey(states, 'code')
);
export const getRoomCategoriesMap = createSelector(
  getRoomCategories,
  (categories) => arrayToMapByKey(categories, 'code')
);
export const getRoomClassesMap = createSelector(getRoomClasses, (classes) =>
  arrayToMapByKey(classes, 'code')
);
export const getRoomsMap = createSelector(getRooms, (rooms) =>
  arrayToMapByKey(rooms, 'code')
);
export const getAttributesMap = createSelector(getAttributes, (attributes) =>
  arrayToMapByKey(attributes, 'code')
);
export const getFloorsMap = createSelector(getFloors, (floors) =>
  arrayToMapByKey(floors, 'code')
);
export const getHousekeepingAttendantsMap = createSelector(
  getHousekeepingAttendants,
  (attendants) => arrayToMapByKey(attendants, 'code')
);
export const getSectionsMap = createSelector(getSections, (sections) =>
  arrayToMapByKey(sections, 'code')
);
export const getOutOfOrderReasonsMap = createSelector(
  getOutOfOrderReasons,
  (reasons) => arrayToMapByKey(reasons, 'code')
);
export const getRoomLocationsMap = createSelector(
  getRoomLocations,
  (locations) => arrayToMapByKey(locations, 'code')
);
export const getNoteTypesMap = createSelector(getNoteTypes, (noteType) =>
  arrayToMapByKey(noteType, 'code')
);
export const getCleanTypesMap = createSelector(getCleanTypes, (cleanType) =>
  arrayToMapByKey(cleanType, 'code')
);

export const getRoomsByIdMap = createSelector(getRooms, (rooms) =>
  arrayToMapByKey(rooms, 'id')
);

export const selectOverlayMessagesState = (
  state: Store
): OverlayMessagesState => state.app.overlayMessages;
export const selectOverlayMessagesOffsetRefs = createSelector(
  selectOverlayMessagesState,
  (state) => state.bottomOffsetRefs
);
export const selectOverlayMessages = createSelector(
  selectOverlayMessagesState,
  (state) => state.messages
);

export const selectCurrentCustomer = (
  state: Store
): CustomerDetails | undefined => state.app.customer;

export const selectNotes = (state: Store): NotesStore => state.app.notes;

export const getActiveRoomQueue = (state: Store): RoomQueueDetailsViewsDto =>
  state.app.roomQueue.active;
export const getArchivedRoomQueue = (
  state: Store
): RoomQueueDetailsViewsDto | undefined => state.app.roomQueue.archived;
