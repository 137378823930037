/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { useActivityCookieCountdown } from '@hkm/features/app/store/activity/behaviours/useActivityCookieCountdown';
import { useActivityCookieEventUpdater } from '@hkm/features/app/store/activity/behaviours/useActivityCookieEventUpdater';
import { useBrowserTabVisibilityDetector } from '@hkm/features/app/store/activity/behaviours/useBrowserTabVisibilityDetector';
import { ActivityContext } from '@hkm/features/app/store/activity/context';
import { useActivityStore } from '@hkm/features/app/store/activity/store';

export const ActivityContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const store = useActivityStore();

  useBrowserTabVisibilityDetector({ store });
  useActivityCookieEventUpdater();
  useActivityCookieCountdown();

  return (
    <ActivityContext.Provider value={store}>
      {children}
    </ActivityContext.Provider>
  );
};
