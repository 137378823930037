import { BadgeColor, DiscrepancyBadgeColor } from '@hkm/shared/enum/badgeColor';

import { DiscrepancyType } from '@ac/library-api';
import { MobileColor } from '@ac/mobile-components/dist/enums';

const colorClassMap = new Map<DiscrepancyType, MobileColor>()
  .set(DiscrepancyType.Skip, MobileColor.DiscrepancySkip)
  .set(DiscrepancyType.Sleep, MobileColor.DiscrepancySleep)
  .set(DiscrepancyType.Occupancy, MobileColor.DiscrepancyOccupancy);

const colorMap = new Map<DiscrepancyType, DiscrepancyBadgeColor>()
  .set(DiscrepancyType.Skip, DiscrepancyBadgeColor.Skip)
  .set(DiscrepancyType.Sleep, DiscrepancyBadgeColor.Sleep)
  .set(DiscrepancyType.Occupancy, DiscrepancyBadgeColor.Occupancy);

export function getDiscrepancyColorClass(status: DiscrepancyType): MobileColor {
  return colorClassMap.get(status) || MobileColor.Gray3;
}

export function getDiscrepancyColor(status: DiscrepancyType): string {
  return colorMap.get(status) || BadgeColor.Gray3;
}
