import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';

import { CleanType } from '@ac/library-api';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import {
  AcModal,
  AcModalBody,
  AcModalHeader,
  AcModalPattern,
} from '@ac/mobile-components/dist/components/modal';
import { BadgeSize, BadgeTheme } from '@ac/web-components';

export interface Props {
  isVisible: boolean;
  onClose: () => void;
  cleanType: CleanType;
}

const CleanTypeDescriptionModal: FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { code, description, longDescription } = props.cleanType;

  const displayLongDescription = useMemo(
    () => getLocalizedString(longDescription),
    [longDescription]
  );

  return (
    <AcModal
      isOpen={props.isVisible}
      onClose={props.onClose}
      pattern={AcModalPattern.Drawer}
    >
      <AcModalHeader showCloseButton={true}>
        {t('GLOBAL.CLEARING_TYPE.TITLE')}
      </AcModalHeader>
      <AcModalBody>
        <AcFormElement label={t('CLEAN_TYPE.DESCRIPTION')}>
          <>
            <ac-badge
              class="ac-spacing-right-sm"
              size={BadgeSize.lg}
              content={code}
              color={props.cleanType.color ?? BadgeColor.Default}
              theme={BadgeTheme.informationalTheme}
            />
            {getLocalizedString(description)}
          </>
        </AcFormElement>
        {displayLongDescription && (
          <AcFormElement
            className="ac-spacing-top-md"
            label={t('CLEAN_TYPE.LONG_DESCRIPTION')}
          >
            {displayLongDescription}
          </AcFormElement>
        )}
      </AcModalBody>
    </AcModal>
  );
};

export default memo(CleanTypeDescriptionModal);
