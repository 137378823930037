/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';
import * as H from 'history';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { MobileColor } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Clickable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

interface Props extends Childless, Clickable {
  link?: H.Location | string;
}

const HeaderCloseButton: FC<Props> = ({ onClick, link }) => {
  const { hide } = useMenuPanelPresenterContext();
  const navigate = useNavigate();

  const openLink = useCallback(() => {
    if (link) {
      if (typeof link === 'string') {
        navigate(generatePath(link));
      }

      navigate(link);
    }
  }, [link, navigate]);

  const onButtonClick = useCallback(() => {
    void hide();
    if (onClick) {
      onClick();
    }
  }, [hide, onClick]);

  return (
    <ac-link onClick={openLink}>
      <AcIconButton
        color={MobileColor.White}
        icon={IconName.cancel}
        onClick={onButtonClick}
      />
    </ac-link>
  );
};

export default memo(HeaderCloseButton);
