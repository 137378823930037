/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HighlightText from '@hkm/components/shared/Utilities/HightlightText/HighlightText';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { debounce } from 'lodash';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

import './SearchField.css';

interface Option {
  id: string;
  text: string;
}

interface ComponentProps {
  optionsArray: Option[];
  label: string;
  value?: Option;
  className?: string;
  testSelector?: string;
  disabled?: boolean;
  onSearch: (value?: string) => void;
  onOptionSelect: (value?: string) => void;
  onClearSelection: () => void;
}

const SearchField = (props: ComponentProps) => {
  const { t } = useTranslation();
  const {
    label,
    className,
    value,
    disabled,
    testSelector,
    onClearSelection,
    onSearch,
    optionsArray,
    onOptionSelect,
  } = props;
  const [searchValue, setSearchValue] = useState<string | undefined>(
    value?.text
  );

  const callSearch = useMemo(
    () =>
      debounce((val?: string) => {
        onSearch(val);
      }, APP_CONSTANTS.DEBOUNCE_TIMEOUT),
    [onSearch]
  );

  const onSearchChange = useCallback(
    (val?: string) => {
      setSearchValue(val);
      // eslint-disable-next-line no-unused-expressions
      val?.length && val?.length > 1 && callSearch(val);
      if (!val) {
        onOptionSelect(undefined);
        callSearch(undefined);
      }
    },
    [callSearch, onOptionSelect]
  );

  const clearSelectedValue = useCallback(() => {
    onClearSelection();
    setSearchValue(undefined);
    onOptionSelect(undefined);
  }, [onClearSelection, onOptionSelect]);

  const searchInfoText = useMemo(() => {
    if (!searchValue || searchValue.length < 2) {
      return t('COMPONENTS.SEARCH_FIELD.ENTER_CHARACTERS');
    }
    if (searchValue && !optionsArray.length) {
      return t('GLOBAL.NO_RESULTS_FOUND');
    }

    return '';
  }, [optionsArray.length, searchValue, t]);

  return (
    <ac-flex
      class={`full-width search-field position-relative ${className || ''}`}
      direction={FlexDirection.column}
      grow
    >
      <AcFieldText
        value={searchValue}
        testSelector={testSelector}
        className={value ? 'selected-option' : ''}
        label={label}
        disabled={disabled}
        icon={IconName.search}
        onChange={onSearchChange}
      />
      {searchInfoText && (
        <AcText size={TextSize.Main3}>{searchInfoText}</AcText>
      )}
      {value && (
        <AcButton
          className="clear-button"
          pattern={AcButtonPattern.Tertiary}
          disabled={disabled}
          testSelector="clear"
          onClick={clearSelectedValue}
        >
          <ac-icon icon={IconName.cancel} />
        </AcButton>
      )}

      {((searchValue && searchValue.length > 1) || value?.id) &&
        optionsArray.map((option) => {
          const setSelectedValue = () => {
            onOptionSelect(option.id);
          };

          return (
            <ac-flex
              key={option.id}
              class="full-width"
              direction={FlexDirection.column}
              onClick={setSelectedValue}
            >
              <ac-flex
                alignItems={AlignItems.center}
                data-test-selector={`item-${option.id}`}
              >
                <ac-radio-button
                  class="ac-padding-right-sm"
                  disabled={disabled}
                  checked={option.id === value?.id}
                  data-test-selector={`item-${option.id}-radio`}
                />
                <HighlightText
                  text={option.text}
                  searchValue={searchValue || option.text}
                />
              </ac-flex>
            </ac-flex>
          );
        })}
    </ac-flex>
  );
};

export default SearchField;
