import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import NextUseModalReservationBody from '@hkm/components/shared/NextUse/NextUseModal/body/NextUseModalReservationBody';
import MaintenanceDetailsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/MaintenanceDetailsTile';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';

import { isDefined } from '@ac/library-utils/dist/utils';
import {
  AcModal,
  AcModalBody,
  AcModalHeader,
  AcModalPattern,
} from '@ac/mobile-components/dist/components/modal';
import { AcTileVariant } from '@ac/mobile-components/dist/components/tile';

export interface NextUseModalProps {
  isVisible: boolean;
  onClose: () => void;
  showGuestNames: boolean;
  unifiedRoomDetails: UnifiedRoomDetails;
  groupedReservedKinds: GroupedReservedKinds;
  source: SourceModuleType;
}

const NextUseModal: FC<NextUseModalProps> = (props: NextUseModalProps) => {
  const { t } = useTranslation();
  const isReservation =
    props.groupedReservedKinds.nextReservedKind === ReservedKind.Reservation;

  const nextReservations = [
    props.groupedReservedKinds.nextMainReservation,
    ...(props.groupedReservedKinds.nextSharedReservations || []),
  ].filter(isDefined);

  return (
    <AcModal
      className="next-use-modal"
      isOpen={props.isVisible}
      onClose={props.onClose}
      pattern={AcModalPattern.Drawer}
    >
      <AcModalHeader className="next-use-modal-header" showCloseButton={true}>
        {t('NEXT_USE_MODAL.LABEL')}
      </AcModalHeader>

      <AcModalBody className="next-use-modal-body">
        {isReservation ? (
          <NextUseModalReservationBody
            roomId={props.unifiedRoomDetails.id}
            showGuestNames={props.showGuestNames}
            reservations={nextReservations || []}
            source={props.source}
          />
        ) : (
          <MaintenanceDetailsTile
            readonly={true}
            hideStateBadge={true}
            variant={AcTileVariant.Borderless}
            unifiedRoomDetails={props.unifiedRoomDetails}
            maintenanceDetails={props.groupedReservedKinds.nextMaintenance}
          />
        )}
      </AcModalBody>
    </AcModal>
  );
};

export default memo(NextUseModal);
