import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { CodeValue, ReservationDashboardMembershipDto } from '@ac/library-api';
import { BadgeSize } from '@ac/web-components';

interface ComponentProps {
  vipStatusCode?: CodeValue;
  memberships?: Array<ReservationDashboardMembershipDto | CodeValue>;
}

const VipAndMember = (props: ComponentProps) => {
  const { membershipLevels, guestStatuses } = useSelector(getRelationsState);

  const memberships = useMemo(
    () =>
      (props.memberships || []).map((membership) => {
        const membershipInLevels = membershipLevels.find((level) => {
          if ((membership as ReservationDashboardMembershipDto).levelCode) {
            return (
              level.code ===
              (membership as ReservationDashboardMembershipDto).levelCode
            );
          }

          return level.code === (membership as CodeValue).code;
        });

        return {
          id: membershipInLevels?.id,
          text: membershipInLevels?.code,
          color: membershipInLevels?.color,
        };
      }),
    [props.memberships, membershipLevels]
  );

  const vipStatus = useMemo(
    () =>
      props.vipStatusCode
        ? guestStatuses.find(({ code }) => code === props.vipStatusCode?.code)
        : undefined,
    [props.vipStatusCode, guestStatuses]
  );

  return (
    <>
      {vipStatus && (
        <ac-badge
          class="ac-spacing-right-sm ac-spacing-bottom-sm"
          content={vipStatus.code}
          size={BadgeSize.lg}
          color={vipStatus.color || BadgeColor.Warning}
          data-test-selector="task-area-vip"
        />
      )}
      {!!memberships.length &&
        memberships.map((membership, index) => (
          <ac-badge
            key={membership.id}
            class="ac-spacing-right-sm ac-spacing-bottom-sm"
            content={membership.text}
            size={BadgeSize.lg}
            color={membership.color || BadgeColor.Alert}
            data-test-selector={`task-area-membership-${index}`}
          />
        ))}
    </>
  );
};

export default VipAndMember;
