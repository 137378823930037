/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import {
  InactivityModalInput,
  InactivityModalPresenterContext,
} from '@hkm/features/app/modals/inactivity/presenter/context';

import {
  PropsWithChildren,
  useModalPresenterStore,
} from '@ac/react-infrastructure';

export const InactivityModalPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useModalPresenterStore<InactivityModalInput, void>();

  return (
    <InactivityModalPresenterContext.Provider value={store}>
      {props.children}
    </InactivityModalPresenterContext.Provider>
  );
};
