import { useCallback } from 'react';
import { ActivityStore } from '@hkm/features/app/store/activity/store';

interface Input {
  store: ActivityStore;
}

interface Output {
  onVisibilityChanged(): void;
}

export function useChangeBrowserTabVisibility({ store }: Input): Output {
  const onVisibilityChanged = useCallback(() => {
    store.browserTabVisibilityChanged();
  }, [store]);

  return {
    onVisibilityChanged,
  };
}
