/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@hkm/features/app/store/app/context';
import { DictionarySelectFormat } from '@hkm/features/configuration/dictionaries/interfaces';
import { useActiveProperty } from '@hkm/features/property/useActiveProperty';
import { useChangeProperty } from '@hkm/features/property/useChangeProperty';

import {
  AcSelect,
  AcSelectPattern,
} from '@ac/mobile-components/dist/components/select';

export const Properties = (): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useAppContext();
  const { propertyId } = useActiveProperty();
  const { changeProperty } = useChangeProperty();

  const properties = useMemo((): DictionarySelectFormat[] => {
    return state.properties.map((property) => ({
      value: property.unitId,
      itemLabel: property.name ?? '',
      inputLabel: property.name,
    }));
  }, [state.properties]);

  return (
    <AcSelect
      selectedItem={propertyId ?? ''}
      placeholder={
        properties.length <= 0
          ? t('MENU.PROPERTY.PROPERTIES_NOT_FOUND')
          : t('MENU.PROPERTY.CHOOSE_PROPERTY')
      }
      itemsList={properties}
      label={t('MENU.PROPERTY.LABEL')}
      onChange={changeProperty}
      showSearch={true}
      clearable={false}
      readonly={properties.length <= 1}
      pattern={AcSelectPattern.Borderless}
      testSelector="property"
    />
  );
};
