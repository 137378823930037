import { createContext } from 'react';

import { ModalPresenterStore } from '@ac/react-infrastructure';
import { useDefinedContext } from '@ac/react-infrastructure/dist/features';

export interface InactivityModalInput {
  duration: number;
}

export type InactivityModalPresenterStore = ModalPresenterStore<
  InactivityModalInput,
  void
>;

export const InactivityModalPresenterContext = createContext<
  InactivityModalPresenterStore | undefined
>(undefined);

export const useInactivityModalPresenterContext =
  (): InactivityModalPresenterStore =>
    useDefinedContext(InactivityModalPresenterContext);
