/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoomConditionsBadge } from '@hkm/components/shared/RoomConditions/RoomConditionsBadge';

import { IdCodeDescriptionColorDto } from '@ac/library-api';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcMultiSelect } from '@ac/mobile-components/dist/components/multiselect';

interface Props {
  isReadOnly: boolean;
  roomConditions: Array<{ value: string; itemLabel: string }>;
  selectedRoomConditions: Array<IdCodeDescriptionColorDto<string>> | undefined;
  testSelectorPrefix: string;
  onRoomConditionsChange(newSelectedConditions: string[]): void;
}

export const RoomConditionsField = ({
  roomConditions,
  selectedRoomConditions,
  testSelectorPrefix,
  onRoomConditionsChange,
  isReadOnly,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const selectedRoomConditionsList = (selectedRoomConditions || []).map(
    ({ id }) => id
  );

  if (isReadOnly && !selectedRoomConditions) {
    return null;
  }

  if (isReadOnly) {
    return (
      <AcFormElement
        label={t('ROOM_DETAILS.ROOM_CONDITIONS')}
        testSelector={`${testSelectorPrefix}-room-conditions`}
      >
        <RoomConditionsBadge roomConditions={selectedRoomConditions} />
      </AcFormElement>
    );
  }

  const translations = {
    searchPlaceholderTranslation: t('GLOBAL.FILL'),
    searchLabelTranslation: t('GLOBAL.SEARCH_LABEL'),
    itemNotFoundTranslation: t('GLOBAL.NO_CONTENT.TITLE'),
    confirmButtonTranslation: t('GLOBAL.CONFIRM'),
    checkAllButtonTranslation: t('GLOBAL.CHECK_ALL'),
    clearAllButtonTranslation: t('GLOBAL.CLEAR_ALL'),
    clearButtonTranslation: t('GLOBAL.CLEAR'),
  };

  return (
    <AcMultiSelect
      placeholder={t('GLOBAL.SELECT')}
      label={t('ROOM_DETAILS.ROOM_CONDITIONS')}
      itemsList={roomConditions}
      selectedItem={selectedRoomConditionsList}
      testSelector={`${testSelectorPrefix}-room-conditions`}
      showInputs
      showSearch
      onChange={onRoomConditionsChange}
      translations={translations}
    />
  );
};
