import { useCallback, useEffect, useRef } from 'react';
import { useInactivityModalPresenterContext } from '@hkm/features/app/modals/inactivity/presenter/context';
import { useIdleTimeoutCookieController } from '@hkm/features/app/store/activity/commands/useIdleTimeoutCookieController';
import { useMobileFeaturesStore } from '@hkm/features/app/store/features/store';

import { LoginService } from '@ac/library-utils/dist/services';
import { ModalResultType } from '@ac/react-infrastructure';

export function useActivityCookieCountdown() {
  const interval = useRef<ReturnType<typeof setInterval>>();
  const cookie = useIdleTimeoutCookieController();
  const presenter = useInactivityModalPresenterContext();
  const { app } = useMobileFeaturesStore();

  const cleanUpInterval = useCallback((): void => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = undefined;
    }
  }, []);

  const setUpInterval = useCallback((): void => {
    if (
      app.sessionIdleTimeoutWarningInSeconds === undefined ||
      app.sessionIdleTimeoutInSeconds === undefined
    ) {
      return;
    }

    const duration =
      app.sessionIdleTimeoutInSeconds - app.sessionIdleTimeoutWarningInSeconds;

    interval.current = setInterval(async () => {
      if (!cookie.isOverdue()) {
        return;
      }

      cleanUpInterval();

      if (duration === 0) {
        return LoginService.logout();
      }

      if (!presenter.state.isVisible) {
        const result = await presenter.show({
          duration,
        });

        return result.resultType === ModalResultType.Canceled
          ? LoginService.logout()
          : setUpInterval();
      }
    }, 1000);
  }, [
    app.sessionIdleTimeoutInSeconds,
    app.sessionIdleTimeoutWarningInSeconds,
    cleanUpInterval,
    cookie,
    presenter,
  ]);

  useEffect(() => {
    setUpInterval();

    return () => {
      cleanUpInterval();
    };
  }, [setUpInterval, cleanUpInterval]);
}
