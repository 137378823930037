import { useCallback, useMemo } from 'react';
import { useMobileFeaturesStore } from '@hkm/features/app/store/features/store';

import { CookiesService } from '@ac/library-utils/dist/services';

interface Output {
  isOverdue(): boolean;
  update(): void;
  remove(): void;
}

export const IDLE_TIMEOUT_COOKIE_KEY = 'hkm_idle_timeout';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export function useIdleTimeoutCookieController(): Output {
  const { app } = useMobileFeaturesStore();

  const idleTimeoutInMilliseconds = useMemo(() => {
    return (
      (app.sessionIdleTimeoutWarningInSeconds ??
        app.sessionIdleTimeoutInSeconds ??
        ONE_DAY_IN_SECONDS) * 1000
    );
  }, [app.sessionIdleTimeoutInSeconds, app.sessionIdleTimeoutWarningInSeconds]);

  const getNextIdleTimeout = useCallback((): number => {
    return Date.now() + idleTimeoutInMilliseconds;
  }, [idleTimeoutInMilliseconds]);

  const update = useCallback((): void => {
    CookiesService.setCookie(
      IDLE_TIMEOUT_COOKIE_KEY,
      getNextIdleTimeout().toString()
    );
  }, [getNextIdleTimeout]);

  const remove = useCallback((): void => {
    CookiesService.deleteCookie(IDLE_TIMEOUT_COOKIE_KEY);
  }, []);

  const isOverdue = useCallback((): boolean => {
    const cookie = CookiesService.getCookie(IDLE_TIMEOUT_COOKIE_KEY);

    if (!cookie) {
      return false;
    }

    const timestamp = parseInt(cookie, 10);

    if (isNaN(timestamp)) {
      return false;
    }

    return Date.now() >= timestamp;
  }, []);

  return {
    isOverdue,
    update,
    remove,
  };
}
