import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SheetSummaryMembership } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryMembership';
import { BadgeWithCount } from '@hkm/components/shared/BadgeWithCount/BadgeWithCount';
import classNames from 'classnames';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { FlexDirection, TextSize } from '@ac/mobile-components/dist/enums';

export interface MembershipInfoProps {
  membership: SheetSummaryMembership;
}

export const MembershipInfo: React.FC<MembershipInfoProps> = (
  props: MembershipInfoProps
) => {
  const { t } = useTranslation();

  return (
    <ac-flex
      direction={FlexDirection.column}
      data-test-selector="membership-info"
    >
      <AcText size={TextSize.Main2}>
        {t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.TYPE')}
      </AcText>

      <AcText size={TextSize.Main1}>{props.membership.description}</AcText>

      <AcText
        size={TextSize.Main2}
        className="ac-spacing-top-md ac-spacing-top-xs"
      >
        {t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.VIPS_AND_MEMBERSHIPS.LEVEL')}
      </AcText>

      <ac-flex wrapped={true}>
        {props.membership.levels.map((level, levelIndex) => (
          <BadgeWithCount
            key={levelIndex}
            count={level.count}
            content={level.description}
            color={level.color}
            className={classNames(
              levelIndex !== props.membership.levels.length - 1 &&
                'ac-spacing-right-sm',
              'ac-spacing-bottom-sm'
            )}
            testSelector="membership-info-level-badge"
          />
        ))}
      </ac-flex>
    </ac-flex>
  );
};

export default memo(MembershipInfo);
