/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FinalizeTaskProcessModalSelectors } from '@hkm/features/attendant/modals/finalizeTaskProcess/Modal.selectors';
import { useFinalizeTaskProcessModalPresenterContext } from '@hkm/features/attendant/modals/finalizeTaskProcess/presenter/context';

import { HousekeepingStatus } from '@ac/library-api';
import {
  AcModal,
  AcModalBody,
  AcModalHeader,
  AcModalPattern,
} from '@ac/mobile-components/dist/components/modal';
import { AlignItems } from '@ac/mobile-components/dist/enums';
import {
  ButtonFill,
  ButtonPattern,
  FlexDirection,
  IconName,
  JustifyContent,
} from '@ac/web-components';

export const FinalizeTaskProcessModal = (): JSX.Element | null => {
  const { t } = useTranslation();
  const presenter = useFinalizeTaskProcessModalPresenterContext();

  const selectStatus = useCallback(
    (housekeepingStatus: HousekeepingStatus) =>
      presenter.confirm({ housekeepingStatus }),
    [presenter]
  );
  const cancel = useCallback(() => presenter.cancel(), [presenter]);

  return (
    <AcModal
      isOpen={presenter.state.isVisible}
      onClose={cancel}
      pattern={AcModalPattern.Drawer}
      testSelector={FinalizeTaskProcessModalSelectors.modal}
    >
      <AcModalHeader showCloseButton={true}>
        {t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.FINISH_CLEANING_MODAL.TITLE')}
      </AcModalHeader>

      <AcModalBody>
        <ac-flex
          class="ac-gap-md ac-padding-sm"
          direction={FlexDirection.row}
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.center}
        >
          <ac-box>
            <ac-button
              onClick={() => selectStatus(HousekeepingStatus.Vacant)}
              pattern={ButtonPattern.secondary}
              fill={ButtonFill.max}
              data-test-selector={
                FinalizeTaskProcessModalSelectors.actions.vacant
              }
            >
              <ac-button-content
                icon={IconName.cleanVac}
                text={t(
                  'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.FINISH_CLEANING_MODAL.CLEAN_VACANT'
                )}
              />
            </ac-button>
          </ac-box>

          <ac-box>
            <ac-button
              onClick={() => selectStatus(HousekeepingStatus.Occupied)}
              pattern={ButtonPattern.secondary}
              fill={ButtonFill.max}
              data-test-selector={
                FinalizeTaskProcessModalSelectors.actions.occupied
              }
            >
              <ac-button-content
                icon={IconName.cleanOcc}
                text={t(
                  'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.FINISH_CLEANING_MODAL.CLEAN_OCCUPIED'
                )}
              />
            </ac-button>
          </ac-box>
        </ac-flex>
      </AcModalBody>
    </AcModal>
  );
};
