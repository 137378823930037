import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { IdCodeDescriptionColor } from '@ac/library-api';
import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
} from '@ac/mobile-components/dist/components/modal';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { BadgeSize } from '@ac/web-components';

import './GuestDisabilitiesTemplate.css';

interface GuestDisabilitiesTemplateProps extends Testable {
  disabilities?: IdCodeDescriptionColor[];
  disableModal?: boolean;
}

const GuestDisabilitiesTemplate: FC<GuestDisabilitiesTemplateProps> = (
  props: GuestDisabilitiesTemplateProps
) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  function openModal(): void {
    if (!props.disableModal) {
      setModalVisibility(true);
    }
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  return (
    <>
      <ac-flex
        wrapped={true}
        class="ac-gap-sm"
        data-test-selector={props.testSelector}
      >
        {props.disabilities?.map((disability) => (
          <ac-badge
            key={disability.id}
            content={disability.code}
            color={disability.color || BadgeColor.Alert}
            onClick={openModal}
            size={BadgeSize.lg}
          />
        ))}
      </ac-flex>

      <AcModal
        className="disabilities-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader
          className="disabilities-modal-header"
          showCloseButton={true}
        >
          {t('ROOM_DETAILS.DISABILITIES')}
        </AcModalHeader>

        <AcModalBody className="disabilities-modal-body">
          {props.disabilities?.map((disability) => (
            <div key={disability.id} className="ac-spacing-top-sm">
              <ac-badge
                content={disability.code}
                color={disability.color || BadgeColor.Alert}
                text={disability.description}
                size={BadgeSize.lg}
                data-test-selector={`${props.testSelector}-${disability.code}`}
              />
            </div>
          ))}
        </AcModalBody>

        <AcModalFooter>
          <AcButton onClick={closeModal} className="disabilities-modal-button">
            <AcButtonContent text={t('GLOBAL.CLOSE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(GuestDisabilitiesTemplate);
