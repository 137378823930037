import APP_ROUTES from '@hkm/constants/routing.constants';

import { acConfig } from '@ac/library-utils/dist/declarations';

const MOBILE_APP_NAME = 'housekeepingMobile';
const LOCAL_IP = new RegExp(
  /((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))/gm
);

const getFrontendModuleUrl = (
  name: keyof typeof acConfig.frontendUrls
): string => {
  const url = acConfig.frontendUrls[name];

  if (window.location.hostname.match(LOCAL_IP)?.length ?? 0 > 0) {
    return window.location.origin;
  }

  if (window.location.hostname === 'localhost' && name === MOBILE_APP_NAME) {
    return window.location.origin;
  }

  return url;
};

export const getRootUrl = (): string => {
  return `${getFrontendModuleUrl('housekeepingMobile')}`;
};

const getLoginUrl = (): string => {
  return `${getFrontendModuleUrl('housekeepingMobile')}${APP_ROUTES.LOGIN}`;
};

export default getLoginUrl;
