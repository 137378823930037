/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipsItem } from '@hkm/components/shared/Chips/ChipsItem';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import classNames from 'classnames';

import {
  AcButton,
  AcButtonSize,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { AlignItems, BadgeSize } from '@ac/web-components';

import './Chips.css';

interface ChipsProps extends Styleable, Childless, Testable {
  items: Array<ChipsItem<string>>;
  onRemove: (value: string) => void;
  onClear: () => void;
}

const Chips: FC<ChipsProps> = (props: ChipsProps) => {
  const { t } = useTranslation();

  const className: string = classNames('chips', props.className);

  return (
    <div className={className} style={props.style}>
      <ac-flex class="chips-scroll" alignItems={AlignItems.center}>
        <AcButton
          size={AcButtonSize.Small}
          onClick={props.onClear}
          className="ac-spacing-left-sm"
          testSelector={'chips-clear-all'}
        >
          <AcButtonContent text={t('GLOBAL.CLEAR_ALL')} />
        </AcButton>
        <ac-flex class="ac-gap-sm ac-spacing-left-sm">
          {props.items.map((item) => (
            <ac-badge
              key={item.id}
              color={BadgeColor.Gray4}
              content={item.label}
              size={BadgeSize.lg}
              onRemoveCallback={() => props.onRemove(item.value)}
              data-test-selector={`chips-${item.value}`}
            />
          ))}
        </ac-flex>
      </ac-flex>
    </div>
  );
};

export default memo(Chips);
