/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useEffect } from 'react';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';

export const HomePage = (): null => {
  const { state, show } = useMenuPanelPresenterContext();

  useEffect(() => {
    if (!state.shouldBeRendered) {
      void show({
        hideCloseButton: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
