/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import {
  FinalizeTaskProcessModalOutput,
  FinalizeTaskProcessModalPresenterContext,
} from '@hkm/features/attendant/modals/finalizeTaskProcess/presenter/context';

import {
  PropsWithChildren,
  useModalPresenterStore,
} from '@ac/react-infrastructure';

export const FinalizeTaskProcessModalPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useModalPresenterStore<void, FinalizeTaskProcessModalOutput>();

  return (
    <FinalizeTaskProcessModalPresenterContext.Provider value={store}>
      {props.children}
    </FinalizeTaskProcessModalPresenterContext.Provider>
  );
};
