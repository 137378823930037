import React, { FC, memo } from 'react';
import { Chips } from '@hkm/components/TaskManagement/Dashboard/config/chipsConfig';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { BadgeSize } from '@ac/web-components';

interface ComponentProps {
  chips: Chips;
}

const DashboardFiltersChipsItem: FC<ComponentProps> = (props) => {
  return (
    <ac-badge
      color={BadgeColor.Gray4}
      content={props.chips.fieldDisplayValue}
      size={BadgeSize.lg}
      onRemoveCallback={props.chips.clearFieldCallback}
      data-test-selector={`chips-${props.chips.fieldDisplayValue}`}
    />
  );
};

export default memo(DashboardFiltersChipsItem);
