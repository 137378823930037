/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskStatusDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { getLuhnAlghorithmValidationObject } from '@hkm/utils/getLuhnAlghorithmValidationObject';

import { TaskStatusRole } from '@ac/library-api';
import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
  AcModalPattern,
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  BadgeSize,
  Color,
  ErrorType,
  FlexGap,
  IconName,
  Size,
} from '@ac/web-components';

import './../TaskModals.css';

export interface ComponentProps {
  statuses: TaskStatusDictionary[];
  isVisible: boolean;
  currentStatusId?: string;
  onChange: (statusId: string, comment?: string) => void;
  onClose: () => void;
}

const TEXTAREA_MAX_LENGTH = 200;

const TaskChangeStatusModal: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const hasPermissionToCancelTask = usePermission(Permission.TaskCancel);
  const [checkedStatus, setCheckedStatus] = useState<
    TaskStatusDictionary | undefined
  >();
  const [comment, setComment] = useState<string | undefined>();
  const [commentValidation, setCommentValidation] = useState<
    string | undefined
  >(t('VALIDATORS.IS_REQUIRED'));

  const textAreaValidationStatus = useMemo(() => {
    return commentValidation
      ? [
          {
            description:
              (comment?.length ?? 0) <= TEXTAREA_MAX_LENGTH
                ? commentValidation
                : '',
            severity: ErrorType.error,
          },
        ]
      : [];
  }, [comment?.length, commentValidation]);

  const clearModal = useCallback(() => {
    setCommentValidation(undefined);
    setComment(undefined);
    setCheckedStatus(undefined);
  }, []);

  const closeModal = useCallback(() => {
    props.onClose();
    clearModal();
  }, [clearModal, props]);

  const onCommentChange = useCallback(
    (value?: string) => {
      setComment(value);
      value && value.length <= TEXTAREA_MAX_LENGTH
        ? setCommentValidation(undefined)
        : setCommentValidation(t('VALIDATORS.IS_REQUIRED'));
    },
    [t]
  );

  const changeStatus = useCallback(() => {
    if ((checkedStatus?.isCommentMandatory && !comment) || !checkedStatus) {
      return setCommentValidation(t('VALIDATORS.IS_REQUIRED'));
    }

    props.onChange(checkedStatus.id, comment);
    clearModal();
  }, [checkedStatus, clearModal, comment, props, t]);

  const availableStatuses = useMemo(() => {
    const activeStatuses = props.statuses.filter(({ isActive }) => isActive);
    const statusesWithCurrentExcluded = props.currentStatusId
      ? activeStatuses.filter(({ id }) => id !== props.currentStatusId)
      : activeStatuses;
    if (hasPermissionToCancelTask) {
      return statusesWithCurrentExcluded;
    }

    return statusesWithCurrentExcluded.filter(
      ({ role }) => role !== TaskStatusRole.Cancellation
    );
  }, [hasPermissionToCancelTask, props.currentStatusId, props.statuses]);

  return (
    <AcModal
      className="task-modal"
      isOpen={props.isVisible}
      onClose={closeModal}
      pattern={AcModalPattern.Drawer}
    >
      <AcModalHeader showCloseButton={true} className="task-modal-header">
        {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.CHANGE_STATUS')}
      </AcModalHeader>
      <AcModalBody>
        {(availableStatuses || []).map((status) => {
          const setSelectedStatus = () => {
            setCheckedStatus(status);
            setComment(undefined);
            if (status.isCommentMandatory) {
              setCommentValidation(t('VALIDATORS.IS_REQUIRED'));
            } else {
              setCommentValidation(undefined);
            }
          };

          return (
            <ac-flex
              key={status.id}
              class="full-width"
              direction={FlexDirection.column}
              data-test-selector={`task-status-${status.code}`}
              grow={true}
            >
              <ac-flex
                class="ac-padding-md full-width"
                columnGap={FlexGap.md}
                alignItems={AlignItems.center}
                onClick={setSelectedStatus}
              >
                <ac-radio-button checked={checkedStatus?.id === status.id} />
                <ac-badge
                  size={BadgeSize.lg}
                  content={status.code}
                  color={status.color || '000'}
                />
                <AcText>{status.displayName}</AcText>
              </ac-flex>
              <ac-divider />
            </ac-flex>
          );
        })}
      </AcModalBody>
      <AcModalFooter className="task-modal-footer">
        <ac-flex
          direction={FlexDirection.column}
          grow={true}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
        >
          {checkedStatus?.isCommentMandatory && (
            <ac-flex
              class="full-width ac-spacing-top-xs ac-spacing-bottom-md"
              direction={FlexDirection.column}
              grow={true}
            >
              <ac-textarea
                class="ac-padding-horizontal-lg"
                label={t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.COMMENT')}
                required={true}
                maxLength={TEXTAREA_MAX_LENGTH}
                value={comment}
                onInputCallback={onCommentChange}
                validationStatus={textAreaValidationStatus}
              />
              {getLuhnAlghorithmValidationObject(comment) && (
                <>
                  <AcText size={TextSize.Main3} className="ac-spacing-left-lg">
                    <ac-icon
                      class="ac-spacing-right-sm"
                      icon={IconName.information}
                      color={Color.informational}
                      size={Size.xs}
                    />
                    {getLuhnAlghorithmValidationObject(comment)}
                  </AcText>
                </>
              )}
            </ac-flex>
          )}
          <ac-flex
            class="full-width footer-buttons"
            direction={FlexDirection.column}
            grow={true}
          >
            <AcButton
              disabled={!!commentValidation}
              className="full-width"
              testSelector="change"
              onClick={changeStatus}
            >
              <AcButtonContent text={t('GLOBAL.CHANGE')} />
            </AcButton>
            <AcButton
              pattern={AcButtonPattern.Tertiary}
              className="full-width"
              testSelector="cancel"
              onClick={closeModal}
            >
              <AcButtonContent
                icon={IconName.cancel}
                text={t('GLOBAL.CANCEL')}
              />
            </AcButton>
          </ac-flex>
        </ac-flex>
      </AcModalFooter>
    </AcModal>
  );
};

export default memo(TaskChangeStatusModal);
