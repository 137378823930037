import { useEffect } from 'react';
import { useChangeBrowserTabVisibility } from '@hkm/features/app/store/activity/commands/useChangeBrowserTabVisibility';
import { ActivityStore } from '@hkm/features/app/store/activity/store';

interface Input {
  store: ActivityStore;
}

export function useBrowserTabVisibilityDetector({ store }: Input) {
  const { onVisibilityChanged } = useChangeBrowserTabVisibility({ store });

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [onVisibilityChanged]);
}
