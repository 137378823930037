/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { MobileColor } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

const HeaderHelpButton: FC<Childless> = () => {
  const { hide } = useMenuPanelPresenterContext();
  const navigate = useNavigate();

  const openHelp = useCallback(() => {
    navigate(generatePath(APP_ROUTES.HELP));
  }, [navigate]);

  return (
    <ac-link onClick={openHelp}>
      <AcIconButton
        color={MobileColor.White}
        icon={IconName.help}
        onClick={hide}
      />
    </ac-link>
  );
};

export default memo(HeaderHelpButton);
