import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import MaintenanceStatusBadge from '@hkm/components/shared/MaintenanceStatusBadge/MaintenanceStatusBadge';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import { getDiscrepancyColor } from '@hkm/shared/helpers/getDiscrapencyColor';
import {
  EnabledRoomStatesPurpose,
  useEnabledRoomStates,
} from '@hkm/shared/hooks/useEnabledRoomStates';

import {
  DiscrepancyType,
  RoomMaintenanceStatus,
  RoomStatus,
} from '@ac/library-api';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { BadgeSize } from '@ac/web-components';

import './HelpContainer.css';

const HelpContainer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const roomStates = useEnabledRoomStates(EnabledRoomStatesPurpose.DISPLAY);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <ConnectedHeader close={goBack}>{t('HELP.TITLE')}</ConnectedHeader>
      <AcBody className="help-body">
        <div className="help-section ac-spacing-bottom-md">
          <AcText
            color={MobileColor.Black}
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
            uppercase={true}
          >
            {t('HELP.DISCREPANCY.LABEL')}
          </AcText>
          <ac-flex
            alignItems={AlignItems.center}
            class="ac-spacing-top-md"
            wrapped={true}
          >
            <ac-box sizeSm={8}>
              <ac-badge
                size={BadgeSize.lg}
                content={t('GLOBAL.DISCREPANCY.Skip')}
                color={getDiscrepancyColor(DiscrepancyType.Skip)}
              />
            </ac-box>
            <ac-box sizeSm={8}>
              <ac-badge
                size={BadgeSize.lg}
                content={t('GLOBAL.DISCREPANCY.Sleep')}
                color={getDiscrepancyColor(DiscrepancyType.Sleep)}
              />
            </ac-box>
            <ac-box sizeSm={8} class="ac-spacing-top-md">
              <ac-badge
                size={BadgeSize.lg}
                content={t('GLOBAL.DISCREPANCY.Occupancy')}
                color={getDiscrepancyColor(DiscrepancyType.Occupancy)}
              />
            </ac-box>
          </ac-flex>
        </div>

        <div className="help-section ac-spacing-top-md ac-spacing-bottom-md">
          <AcText
            color={MobileColor.Black}
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
          >
            {t('HELP.RESERVATION_STATUS.LABEL')}
          </AcText>
          <ac-flex alignItems={AlignItems.flexStart}>
            <ac-box sizeSm={8}>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.RESERVED')}</AcText>
              </div>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.DUE_IN')}</AcText>
              </div>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.IN_HOUSE')}</AcText>
              </div>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.DUE_OUT')}</AcText>
              </div>
            </ac-box>
            <ac-box sizeSm={8}>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.CHECKED_OUT')}</AcText>
              </div>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.CANCELLED')}</AcText>
              </div>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.RESERVATION_STATUS.NO_SHOW')}</AcText>
              </div>
            </ac-box>
          </ac-flex>
        </div>

        <div className="help-section ac-spacing-top-md ac-spacing-bottom-md">
          <AcText
            color={MobileColor.Black}
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
            uppercase={true}
          >
            {t('HELP.ROOM_STATUS.LABEL')}
          </AcText>
          <ac-flex alignItems={AlignItems.center} wrapped={true}>
            {roomStates.map((state) => (
              <ac-box key={state.code} sizeSm={8}>
                <div className="ac-spacing-top-md">
                  <RoomStatusBadge
                    status={state.code as RoomStatus}
                    showLabel={true}
                  />
                </div>
              </ac-box>
            ))}
          </ac-flex>
        </div>

        <div className="help-section ac-spacing-top-md ac-spacing-bottom-md">
          <AcText
            color={MobileColor.Black}
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
            uppercase={true}
          >
            {t('HELP.FRONT_DESK_STATUS.LABEL')}
          </AcText>
          <ac-flex alignItems={AlignItems.flexStart}>
            <ac-box sizeSm={8}>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.FRONT_DESK_STATUS.VACANT')}</AcText>
              </div>
            </ac-box>
            <ac-box sizeSm={8}>
              <div className="ac-spacing-top-md">
                <AcText>{t('HELP.FRONT_DESK_STATUS.OCCUPIED')}</AcText>
              </div>
            </ac-box>
          </ac-flex>
        </div>

        <div className="help-section ac-spacing-top-md">
          <AcText
            color={MobileColor.Black}
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
            uppercase={true}
          >
            {t('HELP.OOO_OOS_STATUS.LABEL')}
          </AcText>
          <ac-flex alignItems={AlignItems.flexStart}>
            <ac-box sizeSm={8}>
              <div className="ac-spacing-top-md">
                <AcText>
                  <MaintenanceStatusBadge
                    status={RoomMaintenanceStatus.OutOfOrder}
                    showLabel={true}
                  />
                </AcText>
              </div>
            </ac-box>
            <ac-box sizeSm={8}>
              <div className="ac-spacing-top-md">
                <AcText>
                  <MaintenanceStatusBadge
                    status={RoomMaintenanceStatus.OutOfService}
                    showLabel={true}
                  />
                </AcText>
              </div>
            </ac-box>
          </ac-flex>
        </div>
      </AcBody>
    </>
  );
};

export default HelpContainer;
