import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import classNames from 'classnames';

import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { BadgeSize } from '@ac/web-components';

interface EtaEtdGuaranteedBadgeProps extends Styleable, Testable {}

const EtaEtdGuaranteedBadge: FC<EtaEtdGuaranteedBadgeProps> = (
  props: EtaEtdGuaranteedBadgeProps
) => {
  const { t } = useTranslation();
  const className = classNames('bordered-badge', props.className);

  return (
    <ac-badge
      class={className}
      size={BadgeSize.lg}
      content={t('GLOBAL.ETA_ETD_GUARANTEED')}
      color={BadgeColor.White}
      data-test-selector={props.testSelector}
    />
  );
};

export default memo(EtaEtdGuaranteedBadge);
