import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';

export const disabledMenuSwipeClass = 'disable-menu-swap';

export function useSwipeEvent() {
  const presenter = useMenuPanelPresenterContext();
  const location = useLocation();

  useEffect(() => {
    const isEventNotBlocked = (event: TouchEvent) => {
      const target: Element | undefined =
        event.target instanceof Element ? event.target : undefined;

      return (
        !document.body.classList.contains('ac-modal-global-open') &&
        !target?.closest('.' + disabledMenuSwipeClass) &&
        location.pathname !== '/'
      );
    };

    const showMenu = (event: TouchEvent) => {
      if (isEventNotBlocked(event)) {
        presenter.show({
          hideCloseButton: false,
        });
      }
    };

    const hideMenu = (event: TouchEvent) => {
      if (isEventNotBlocked(event)) {
        presenter.hide();
      }
    };

    document.addEventListener('swiped-left', hideMenu);
    document.addEventListener('swiped-right', showMenu);

    return () => {
      document.removeEventListener('swiped-left', hideMenu);
      document.removeEventListener('swiped-right', showMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
