/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import HeaderMenuButton from '@hkm/components/shared/LayoutComponents/HeaderMenuButton';
import { NavbarRight } from '@hkm/features/app/features/navbar/features/NavbarRight';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';

import { AcHeader } from '@ac/mobile-components/dist/components/layout';

export const Navbar = (): JSX.Element => {
  const { state } = useMicroFrontendsContext();

  return (
    <AcHeader
      color={state.navBar.color}
      left={<HeaderMenuButton />}
      right={<NavbarRight />}
    >
      {state.navBar.customNavbarTitleContent
        ? state.navBar.customNavbarTitleContent
        : state.navBar.title}
    </AcHeader>
  );
};
