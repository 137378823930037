/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getLuhnAlghorithmValidationObject } from '@hkm/utils/getLuhnAlghorithmValidationObject';

import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Disableable,
  Styleable,
  Validatable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { Color, ErrorType, IconName, Size } from '@ac/web-components';

import './CommentModal.css';

const TEXTAREA_MAX_LENGTH = 255;

export interface CommentModalProps extends Styleable, Validatable, Disableable {
  comment: string | undefined;
  roomNumber: string;
  roomType: string;
  onSaveModal(newComment: string): void;
}
const CommentModal: FC<CommentModalProps> = (props: CommentModalProps) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [commentField, setCommentField] = useState<string | undefined>(
    props.comment
  );
  const [commentValidation, setCommentValidation] = useState<
    string | undefined
  >();

  const textAreaValidationStatus = useMemo(() => {
    return commentValidation
      ? [{ description: commentValidation, severity: ErrorType.error }]
      : [];
  }, [commentValidation]);

  const { t } = useTranslation();

  useEffect(() => {
    setCommentField(props.comment);
  }, [props.comment]);

  function openModal(event: { preventDefault: () => void }): void {
    event.preventDefault();
    setModalVisibility(true);
  }

  const onChange = useCallback(
    (newValue: string) => {
      setCommentField(newValue);
      newValue && newValue.length <= TEXTAREA_MAX_LENGTH
        ? setCommentValidation(undefined)
        : setCommentValidation(t('VALIDATORS.IS_REQUIRED'));
    },
    [t]
  );

  function closeModal(): void {
    // rollback changes
    setCommentField(props.comment);
    setModalVisibility(false);
    setCommentValidation(undefined);
  }

  function onSaveModal(): void {
    props.onSaveModal(commentField || '');
    closeModal();
  }

  return (
    <>
      {!props.disabled && (
        <a href="#" onClick={openModal} className="link">
          <AcText color={MobileColor.Black} size={TextSize.Main1}>
            {props.comment}
          </AcText>
        </a>
      )}

      {props.disabled && (
        <AcText color={MobileColor.Black} size={TextSize.Main1}>
          {props.comment}
        </AcText>
      )}

      {props.validation && (
        <a href="#" onClick={openModal} className="unstyled-link">
          <AcText color={MobileColor.Alert} size={TextSize.Main1}>
            {props.validation}
          </AcText>
        </a>
      )}

      <AcModal
        className="comment-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader className="comment-modal-header" showCloseButton={true}>
          {t('ROOM_DETAILS.COMMENT')}
        </AcModalHeader>

        <AcModalBody className="comment-modal-body">
          <ac-flex justifyContent={JustifyContent.spaceBetween}>
            <AcFormElement
              label={t('ROOM_DETAILS.ROOM_NUMBER')}
              flatAndJustify={JustifyContent.spaceBetween}
            >
              {props.roomNumber}
            </AcFormElement>
            <AcFormElement
              label={t('ROOM_DETAILS.ROOM_TYPE')}
              flatAndJustify={JustifyContent.spaceBetween}
            >
              {props.roomType}
            </AcFormElement>
          </ac-flex>

          <ac-textarea
            class="ac-spacing-vertical-md"
            label={t('ROOM_DETAILS.COMMENT')}
            required={true}
            maxLength={TEXTAREA_MAX_LENGTH}
            onInputCallback={onChange}
            value={commentField}
            validationStatus={textAreaValidationStatus}
          />
          {getLuhnAlghorithmValidationObject(commentField) && (
            <>
              <AcText size={TextSize.Main3} className="ac-spacing-left-md">
                <ac-icon
                  class="ac-spacing-right-sm"
                  icon={IconName.information}
                  color={Color.informational}
                  size={Size.xs}
                />
                {getLuhnAlghorithmValidationObject(commentField)}
              </AcText>
            </>
          )}
        </AcModalBody>

        <AcModalFooter>
          <AcButton
            onClick={onSaveModal}
            style={{ width: '100%' }}
            disabled={!!commentValidation}
          >
            <AcButtonContent text={t('GLOBAL.SAVE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(CommentModal);
