import { createContext } from 'react';
import { ActivityStore } from '@hkm/features/app/store/activity/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const ActivityContext = createContext<ActivityStore | undefined>(
  undefined
);

export const useActivityContext = (): ActivityStore =>
  useDefinedContext(ActivityContext);
