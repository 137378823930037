import React, { CSSProperties, ReactElement, useCallback } from 'react';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextSize } from '@ac/mobile-components/dist/enums';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';
import { BadgeSize } from '@ac/web-components';

import './BadgeWithCount.css';

interface RenderCountProps {
  borderClass?: MobileColor | string;
  borderColor?: MobileColor | string;
}
interface BadgeWithCountProps {
  count?: number;
  renderCount?: (props: RenderCountProps) => ReactElement | null;
  testSelector?: string;
  className?: string;
  color?: string;
  content?: string;
}

export const BadgeWithCount = ({
  count,
  renderCount,
  className,
  testSelector,
  color,
  content,
}: BadgeWithCountProps) => {
  const classes = {
    container: 'badge-with-count',
    countContainer: 'badge-with-count-container',
  };

  const internalRenderCount = useCallback(() => {
    const styles: CSSProperties = {
      border: `1px solid ${!color?.includes('#') ? '#' : ''}${color}`,
    };

    if (count !== undefined && count !== null) {
      return (
        <div
          className={classes.countContainer}
          style={styles}
          {...getTestSelectorAttribute(testSelector)}
        >
          <AcText size={TextSize.Main2} testSelector={`${testSelector}-count`}>
            {count}
          </AcText>
        </div>
      );
    }

    return renderCount && renderCount(styles);
  }, [color, count, renderCount, classes.countContainer, testSelector]);

  return (
    <ac-flex class={`${className} ${classes.container}`}>
      <ac-badge size={BadgeSize.lg} color={color} content={content} />
      {internalRenderCount()}
    </ac-flex>
  );
};
