const housekeepingPostingRoute = '/housekeeping-posting';
const attendantPostingRoute = '/attendant-posting';
const housekeepingRoute = '/housekeeping';
const attendantRoute = '/attendant-assignment';
const taskManagementRoute = '/task-management';

const APP_ROUTES = {
  HOME: '/',
  HOUSEKEEPING: {
    DASHBOARD: `${housekeepingRoute}/dashboard`,
    DASHBOARD_V2: '/mobile/housekeeping/rooms',
    DETAILS: `${housekeepingRoute}/details/:id`,
    FILTERS: `${housekeepingRoute}/filters`,
    STATISTICS: '/mobile/housekeeping/rooms/statistics',
    BATCH: `${housekeepingRoute}/batch-status-change`,
    ADD_MAINTENANCE: `${housekeepingRoute}/add-maintenance/:roomId`,
    RELEASE_MAINTENANCE: `${housekeepingRoute}/close-maintenance/:id`,
  },
  MAINTENANCE: {
    DASHBOARD: '/mobile/housekeeping/maintenances',
    STATISTICS: '/mobile/housekeeping/maintenances/statistics',
    ADD: '/mobile/housekeeping/maintenances/create',
    DETAILS: '/mobile/housekeeping/maintenances/:id',
    CLONE: '/mobile/housekeeping/maintenances/:id/clone',
    RELEASE: '/mobile/housekeeping/maintenances/:id/release',
  },
  ATTENDANT_ASSIGNMENTS: {
    SHEET_SUMMARY: attendantRoute,
    SHEET_SUMMARY_V2: '/mobile/housekeeping/assignments',
    ROOM_LIST: `${attendantRoute}/room-list`,
    ROOM_DETAILS: `${attendantRoute}/room-details/:roomId`,
    ADD_MAINTENANCE: `${attendantRoute}/add-maintenance/:roomId`,
  },
  MOBILE_POSTING: {
    DASHBOARD: '/mobile/housekeeping/posting',
    HOUSEKEEPING: {
      DASHBOARD: housekeepingPostingRoute,
      RESERVATIONS: '/mobile/housekeeping/posting/rooms/:roomId',
      POSTING_VIEW:
        '/mobile/housekeeping/posting/rooms/:roomId/reservations/:reservationId',
    },
    ATTENDANT: {
      DASHBOARD: attendantPostingRoute,
      RESERVATIONS: '/mobile/housekeeping/posting/rooms/:roomId',
      POSTING_VIEW:
        '/mobile/housekeeping/posting/rooms/:roomId/reservations/:reservationId',
    },
  },
  TASK_MANAGEMENT: {
    DASHBOARD: `${taskManagementRoute}/dashboard`,
    FILTERS: `${taskManagementRoute}/filters`,
    TASK_PREVIEW: `${taskManagementRoute}/task-preview/:taskId`,
  },
  MICRO_FRONTEND: '/mobile',
  HELP: '/help',
  LOGIN: '/login',
  ACCESS_DENIED: '/403',
};

export default APP_ROUTES;
