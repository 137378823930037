import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { CodeValue } from '@ac/library-api';
import { BadgeSize } from '@ac/web-components';

interface ComponentProps {
  disabilityCodes?: CodeValue[];
}

const SpecialNeeds = (props: ComponentProps) => {
  const { guestStatuses } = useSelector(getRelationsState);

  const disabilities = useMemo(
    () =>
      (props.disabilityCodes || []).map((disabilityCode) => {
        const disabilityCodeInStatuses = guestStatuses.find(
          ({ code }) => code === disabilityCode.code
        );

        return {
          id: disabilityCodeInStatuses?.id,
          text: disabilityCodeInStatuses?.code,
          color: disabilityCodeInStatuses?.color,
        };
      }),
    [props.disabilityCodes, guestStatuses]
  );

  return (
    <>
      {!!disabilities.length &&
        disabilities.map((disability, index) => (
          <ac-badge
            key={disability.id}
            size={BadgeSize.lg}
            class="ac-spacing-right-sm ac-spacing-bottom-sm"
            content={disability.text}
            color={disability.color || BadgeColor.Alert}
            data-test-selector={`task-area-disability-${index}`}
          />
        ))}
    </>
  );
};

export default SpecialNeeds;
