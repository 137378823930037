export enum BadgeColor {
  Default = '84E8FC',
  Blue1 = 'E6FEFE',
  Gray5 = '6F7785',
  Gray4 = 'BAC0CA',
  Gray3 = '878F9C',
  Alert = 'DC2626',
  Warning = 'FB923C',
  WarningLight = 'FDBA74',
  White = 'FFFFFF',
  Black = '20293A',
}

export enum BadgeBorderColor {
  Blue1 = '84E8FC',
}

export enum RoomBadgeColor {
  Inspected = '5CB85C',
  Dirty = 'D9534F',
  Clean = '5BC0DE',
  Pickup = 'F0AD4E',
  Default = '000000',
}

export enum MaintenanceBadgeColor {
  OOO = '000000',
  OOS = '742109',
}

export enum MaintenanceStateColor {
  Active = 'eaa630',
  Pending = '80e8ff',
  Released = '5cb85c',
  Canceled = 'd9534f',
  Default = '000000',
}

export enum RoomQueueBadgeColor {
  Pending = '620173',
  Ready = '109128',
}

export enum DiscrepancyBadgeColor {
  Sleep = 'EAA630',
  Skip = 'D9534F',
  Occupancy = 'AA9732',
}
