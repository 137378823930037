import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import { getIsLoading } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';

import {
  AcFab,
  AcFabChild,
  AcFabContainer,
} from '@ac/mobile-components/dist/components/fab';
import { IconName } from '@ac/web-components';

const DashboardFabContainer: FC = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(getIsLoading);
  const navigate = useNavigate();

  const onClickHandler = useCallback(() => {
    navigate(generatePath(APP_ROUTES.TASK_MANAGEMENT.FILTERS));
  }, [navigate]);

  return (
    <AcFabContainer>
      <AcFab
        icon={IconName.actionMenu}
        expandedIcon={IconName.cancel}
        id="task-management-floating-menu"
        testSelector={'task-management-fab'}
      >
        <AcFabChild
          disabled={isLoading}
          icon={IconName.filter}
          text={t('ADVANCED_FILTERS.TITLE')}
          onClick={onClickHandler}
          testSelector={'task-management-fab-advanced-search'}
        />
      </AcFab>
    </AcFabContainer>
  );
};

export default DashboardFabContainer;
